<template>
    <div class="appointment">
        <!-- 店铺信息 -->
        <shop-info :shopInfo="reserveInfo.shop_details" from="OrderDate"></shop-info>
        <!-- 预约时段 -->
        <div class="time-box">
            <div class="title">预约时间段</div>
            <div class="item flex" v-for="(item, index) in reserveInfo.time_lists" :key="index" @click="checkTime(item, index)">
                <div class="check">
                    <img :src="require('@/assets/' + (checkIndex == index?'dui':'dui2') + '.png')" alt />
                </div>
                <div class="right flex-between">
                    <div class="timer">
                        <p class="t">{{ item.reserve_name }}</p>
                        <p class="d">{{ item.reserve_time }}</p>
                    </div>
                    <div :class="['num',{'disable':item.total_time_reserve == 0}]">剩余{{ item.total_time_reserve }}个</div>
                </div>
            </div>
        </div>
        <!-- 联系人信息 -->
        <van-cell-group>
            <van-field v-model="user" label="联系人" placeholder="请输入联系人姓名" />
            <van-field v-model="phone" label="联系电话" type="number" placeholder="请输入联系人电话" />
            <van-field v-model="notes" rows="2" autosize label="备注" type="textarea" placeholder="若有备注，请留下说明，谢谢！" />
        </van-cell-group>
        <div class="appointment-top-footer" @click="postData">
            <p>立即预约</p>
        </div>
    </div>
</template>

<script>
    import {
        getReserveTimes,
        orderReserve
    } from "@/http/api";
    import ShopInfo from "@/components/shopsInfo/Shop";
    export default {
        components: {
            ShopInfo
        },
        data() {
            return {
                userToken: "",
                checkIndex: -1,
                isPost: false,
                query: {},
                reserveInfo:{},
                user: "",
                phone: "",
                notes: "",
                reserve_time_id: '',
            };
        },
        mounted() {
            let query = this.$route.query
            if (query.reserve_id && query.shop_id ) {
                this.userToken = this.$LStorage.getItem("userToken").user_token;
                this.query = query
                this.getReserveTimes();
            } else {
                this.$dialog.alert({
                    message: '订单预约页面参数异常, 点击跳转到上一页',
                }).then(() => {
                    this.$router.go(-1)
                });
            }
        },
        methods: {
            // 获取订单商品预约时间段和店铺详情
            async getReserveTimes() {
                const res = await getReserveTimes({
                    data: this.query
                });
                this.reserveInfo = res.data;
            },
            // 选择时间
            checkTime(item, index) {
                if (item.total_time_reserve == 0) {
                    this.$toast("很抱歉，您选择的时间段没有啦");
                    return;
                } else {
                    this.checkIndex = index;
                    this.reserve_time_id = item.reserve_time_id
                }
            },
            
            // 提交数据
            async postData() {
                if (this.isPost) {
                    this.$toast.fail("请勿重复提交");
                } else {
                    let checkIndex = this.checkIndex,
                        user = this.user,
                        phone = this.phone,
                        notes = this.notes;
                    if (this.checkIndex == -1) {
                        this.$toast("请选择预约时间段");
                        return;
                    } else if (user == "") {
                        this.$toast("请输入联系人姓名");
                        return;
                    } else if (phone == "") {
                        this.$toast("请输入联系人电话");
                        return;
                    }  else if (!this.checkMobile(phone)) {
                        this.$toast("请输入正确的联系人电话");
                        return false;
                    } else {
                        this.isPost = true;     
                        let res = await orderReserve({
                            data: {
                                user_token: this.userToken,
                                order_id: this.query.order_id,
                                order_cards_id: this.query.order_cards_id,
                                reserve_time_id: this.reserve_time_id,
                                reserve_shop_id: this.query.shop_id,
                                reserve_shop_name: this.reserveInfo.shop_details.shop_name,
                                reserve_user: user,
                                reserve_phone: phone,
                                reserve_notes: notes
                            }
                        });
                        this.isPost = false;
                        if (res.code == 200) {
                            this.$toast.success("预约成功");
                            this.$router.go(-2);
                        } else {
                            this.$toast(res.msgs);
                        }
                    }
                }
            },
            // 验证手机号
            checkMobile(str) {
                let re = /^1[3456789]\d{9}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    };
</script>

<style lang="scss">
    .appointment {
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        padding-bottom: 0.52rem;
        min-height: 100vh;
        font-size: 0.28rem;
        box-sizing: border-box;

        .time-box {
            padding: 0 0.3rem;

            .title {
                font-size: 0.3rem;
                color: #000022;
                line-height: 0.88rem;
            }

            .item {
                margin-bottom: 0.3rem;

                .check {
                    margin-right: 0.2rem;

                    img {
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                }

                .right {
                    background-color: #fff;
                    border-radius: 0.1rem;
                    height: 1.3rem;
                    width: calc(100% - 0.6rem);
                    padding: 0 0.3rem;
                    box-sizing: border-box;

                    .timer {
                        .t {
                            font-size: 0.3rem;
                            color: #000022;
                        }

                        .d {
                            color: #474747;
                            margin-top: 0.1rem;
                        }
                    }

                    .num {
                        color: #fc6f15;
                        font-size: 0.3rem;

                        &.disable {
                            color: #bfbfbf;
                        }
                    }
                }
            }
        }

        .appointment-top-footer {
            background: #f6f6f6;
            padding-top: 1.14rem;
            padding-bottom: 0.45rem;
            box-sizing: border-box;

            p {
                border-radius: 0.44rem;
                height: 0.84rem;
                color: #fff;
                text-align: center;
                line-height: 0.84rem;
                background: #fc6f15;
                font-size: 0.3rem;
                margin: 0 0.34rem;
            }
        }
    }
</style>
